/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// Brands that use this component
// 1800-Flowers (18F)
// 1800-Baskets (18B)

// libraries
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import {
    array, object, func, number, string, shape, bool,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
import { useLazyQuery } from '@apollo/client';
import ReactMarkdown from 'react-markdown';
// helpers
import { getBrand } from '../../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { actions as tagManagerActions } from '../../../../../../../state/ducks/TagManager';
import findURL from '../../../../../../gql/queries/findURL';
import findCategoryPageByURL from '../../../../../../gql/queries/findCategoryPageByURL';
import { getPresentationFamily, getFeatureFlags } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import hexToRgb from '../../../../../../helpers/contentstack/hexToRgb';

import LazyLoadWrapper from '../../../../GraphqlCommonComponents/LazyLoadWrapper/LazyLoadWrapper';
import ResponsiveImage from '../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import useExperimentServiceAttributes from '../../../../../../helpers/experimentService/useExperimentServiceAttributes';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth.upMedium,
            margin: '0 auto 30px auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth.upLarge,
            margin: '0 auto 30px auto',
        },
    },
    containerWithGrid: {
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth.upLarge,
            margin: '0 auto ',
        },
    },
    mainContainer: {
        backgroundColor: theme.palette.tiers?.container?.backgroundColor,
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    tierTitle: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        fontSize: 18,
        fontWeight: 400,
        paddingLeft: 20,
        backgroundColor: theme.palette.tiers?.title.backgroundColor,
        textAlign: theme.palette.tiers?.title.upSmall.textAlign,
        lineHeight: theme.palette.tiers?.title.lineHeight,
        color: theme.palette.tiers?.title.color,
        [theme.breakpoints.down('sm')]: {
            lineHeight: 1.9,
            fontSize: 14,
            padding: 0,
            textAlign: theme.palette.tiers?.title.downSmall.textAlign,
        },
    },
    insideCard: {
        margin: 5,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            '& a': {
                textDecoration: 'none',
            },
        },
    },
    reponsiveImage: {
        maxWidth: '100%',
        height: 'auto',
        display: 'block',
    },
    card: {
        backgroundColor: theme.palette.tiers?.card.backgroundColor,
    },
    tierTitleFlower: {
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            backgroundColor: theme.palette.white,
            color: theme.palette.common?.black,
            justifyContent: 'center',
            fontWeight: 700,
            fontSize: '14px',
        },
    },
    imageShadowAndRadius: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: '10px',
            borderRadius: '5px',
            boxShadow: theme.palette.uiShadow,
        },
    },
    siloPriceInsideCard: {
        textAlign: 'center',
    },
    price: {
        color: theme.palette.cms?.white || '#fffff',
        fontWeight: 700,
        fontSize: 14,
        display: 'block',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            color: theme.palette.cms?.black || '#000000',
            textAlign: 'center',
        },
    },
    header: {
        textAlign: 'center',
        margin: '15px 0',
        fontSize: '21px',
    },
    headerWithBorder: {
        position: 'relative',
        '&::before': {
            content: '""',
            background: '#CCCCCB',
            width: '100%',
            height: '1px',
            position: 'absolute',
            right: 0,
            left: 0,
            top: '50%',
            zIndex: 1,
        },
        '&>div': {
            position: 'relative',
            display: 'inline-block',
            zIndex: 9,
            background: '#FFF',
            padding: '0 15px',
        },
    },
    posAbs: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
    },
    tiersHeader: {
        '& h1,h2,h3': {
            fontWeight: 400,
        },
    },
});
const HomePageTiers = ({
    header, headerColor, headerLineBorder, data, brand: { domain = '' } = {},
    classes, trackEvent, maxPerRow, responsiveHeight, presentationFamily, featureFlags, tilePadding, categoryTiers,
}) => {
    const { targeting, context, isGraphqlTargetingEnabled } = useExperimentServiceAttributes({ queryName: 'findCategoryPageByUrl' });
    const getCard = (tier, tileStyleOverrides, isLast = false) => {
        const {
            link: { title = '', href = '' } = {},
            mobile_image = {},
            price,
            image,
            title_background_color,
            title_font_color,
            title_text_alignment,
            background_transparancy,
            title_padding,
            seo_alt_text,
            title_position,
            title_line_height,
            tracking_event_action,
            tracking_event_category,
            tracking_event_label,
            font_weight,
            margin_override,
            image_shadow_and_radius = true,
        } = tier;
        // Apollo prefetching from mouseOver TODO mobile
        const siloPriceEnabled = featureFlags['is-silos-display-price-enabled'] && presentationFamily === 'flower';
        const FIND_URL_QUERY = findURL(domain, href);
        const FIND_CATEGORY_PAGE_QUERY = findCategoryPageByURL(isGraphqlTargetingEnabled, domain, href);

        const [loadURL, { errorURL }] = useLazyQuery(FIND_URL_QUERY);
        const [loadCategory, { errorCategory }] = useLazyQuery(FIND_CATEGORY_PAGE_QUERY);
        const categoryTier = categoryTiers && presentationFamily === 'flower';
        if (errorURL || errorCategory) { mbpLogger.logError({ appName: process.env.npm_package_name, message: `Apollo pre-fetching error in: errorURL: ${errorURL} || errorCategory ${errorCategory}` }); }
        // Postition of title
        let titlePositionTop = 'auto';
        let titlePositionBottom = '0';
        switch (title_position) {
            case 'Top':
                titlePositionTop = '0';
                titlePositionBottom = 'auto';
                break;
            case 'Bottom':
                titlePositionTop = 'auto';
                titlePositionBottom = '0';
                break;
            case 'Middle':
                titlePositionTop = '42%';
                titlePositionBottom = '41%';
                break;
            default:
                titlePositionTop = 'auto';
                titlePositionBottom = '0';
        }
        const styleOverrides = {};
        if (title_background_color) styleOverrides.backgroundColor = hexToRgb(title_background_color, background_transparancy);
        if (title_font_color) styleOverrides.color = title_font_color;
        if (title_text_alignment) styleOverrides.textAlign = title_text_alignment;
        if (title_padding) styleOverrides.padding = title_padding;
        if (title_line_height) styleOverrides.lineHeight = title_line_height;
        if (siloPriceEnabled && domain === 'berries') styleOverrides.paddingBottom = '0px';
        if (font_weight) styleOverrides.fontWeight = font_weight;
        styleOverrides.top = titlePositionTop;
        styleOverrides.bottom = titlePositionBottom;

        return (
            <Grid style={tileStyleOverrides} className={classes.card} key={title} item xs={isLast ? 12 : 12 / maxPerRow}>
                <div
                    className={siloPriceEnabled ? `${classes.siloPriceInsideCard} ${classes.insideCard}` : classes.insideCard}
                    style={{ margin: margin_override }}
                >
                    <Link
                        id={href}
                        to={href}
                        onClick={() => trackEvent({
                            eventCategory: tracking_event_category, eventAction: tracking_event_action, eventLabel: tracking_event_label,
                        })}
                        onMouseOver={() => {
                            loadURL();
                            loadCategory({
                                variables: {
                                    ...(isGraphqlTargetingEnabled ? { targeting } : {}),
                                },
                                context,
                            });
                        }}
                        onFocus={() => {
                            loadURL();
                            loadCategory({
                                variables: {
                                    ...(isGraphqlTargetingEnabled ? { targeting } : {}),
                                },
                                context,
                            });
                        }}
                        title={seo_alt_text || title}
                    >
                        {   /* DO NOT REMOVE - USED FOR RESPONSIVE HEIGHT FOR CLS */
                            responsiveHeight && <div style={{ paddingBottom: responsiveHeight }} />
                        }
                        <LazyLoadWrapper
                            config={{
                                once: true,
                                offset: 200,
                            }}
                        >
                            <ResponsiveImage
                                className={`${responsiveHeight && classes.posAbs} ${presentationFamily === 'flower' && image_shadow_and_radius && classes.imageShadowAndRadius} ${classes.reponsiveImage}`}
                                path={`${image ? image?.url : mobile_image?.url}`}
                                params={{ desktop: 'quality=100', mobile: 'quality=100' }}
                                alt={seo_alt_text || title}
                            />
                            <div
                                className={presentationFamily === 'flower' ? `${classes.tierTitleFlower} ${classes.tierTitle}` : classes.tierTitle}
                                style={styleOverrides}
                            >
                                {title && (
                                    <span>
                                        {title}
                                    </span>
                                )}
                                {(siloPriceEnabled || categoryTier) && price && (
                                    <span className={classes.price}>
                                        {price}
                                    </span>
                                )}
                            </div>
                        </LazyLoadWrapper>
                    </Link>
                </div>
            </Grid>
        );
    };

    // 7714: no support for cases such as 5 tiers and 3 maxPerRow, modulo result is 2. Last two tiers will have awkward styling/spacing, to be addressed after .pop bug is fixed.
    let allTiersExceptLast = [];
    let lastTier = null;
    if (data?.length % maxPerRow) {
        allTiersExceptLast = data.slice(0, data.length - 1);
        lastTier = data[data.length - 1];
    }
    const tileStyleOverrides = {};
    tileStyleOverrides.padding = tilePadding;
    const ffHasHomepageGridStructure = featureFlags['has-homepage-grid-structure'];

    return (
        <>
            {header && (
                <div style={{ color: headerColor }} className={`${classes.header} ${headerLineBorder && classes.headerWithBorder}`}>
                    <ReactMarkdown source={header} className={classes.tiersHeader} />
                </div>
            )}
            <Grid container direction="row" justify="center" alignItems="center" className={`${classes.container} ${ffHasHomepageGridStructure && classes.containerWithGrid}`}>
                <Grid className={classes.mainContainer} container spacing={3}>
                    {allTiersExceptLast.length > 0 ? (
                        <>
                            {allTiersExceptLast.map((tier) => (getCard(tier, tileStyleOverrides)))}
                        </>
                    ) : (
                        <>
                            {data?.map((tier) => (getCard(tier, tileStyleOverrides)))}
                        </>
                    )}
                    {lastTier ? (
                        getCard(lastTier, tileStyleOverrides, true)
                    ) : null}
                </Grid>
            </Grid>
        </>
    );
};

const mapDispatchToProps = {
    trackEvent: tagManagerActions.trackEvent,
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
    presentationFamily: getPresentationFamily(state),
    featureFlags: getFeatureFlags(state),
});

HomePageTiers.propTypes = {
    classes: object.isRequired,
    data: array.isRequired,
    trackEvent: func.isRequired,
    brand: object.isRequired,
    maxPerRow: number,
    presentationFamily: string.isRequired,
    featureFlags: shape({
        'is-silos-display-price-enabled': bool,
    }).isRequired,
    header: string,
    headerColor: string,
    headerLineBorder: bool,
    responsiveHeight: string,
    tilePadding: string,
    categoryTiers: bool,
};

HomePageTiers.defaultProps = {
    maxPerRow: 2,
    header: '',
    headerColor: '',
    headerLineBorder: false,
    responsiveHeight: '',
    tilePadding: '',
    categoryTiers: false,
};

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
);
export default enhance(HomePageTiers);
