/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { shape, number, bool } from 'prop-types';
import DesktopHPTiers from './Desktop/HomepageTiers';

const GraphqlHPTiers = ({
    data,
    categoryTiers,
}) => {
    const {
        header = '', header_line_border = false, title_font_color = '', category_group = [], max_per_row = 2, padding_bottom,
    } = data || {};

    const tilePadding = data?.padding || '';
    if (data?.reference?.[0]) {
        return (
            <DesktopHPTiers
                header={header}
                headerColor={title_font_color}
                headerLineBorder={header_line_border}
                data={data.reference[0]?.category_group}
                maxPerRow={data.reference[0]?.max_per_row}
                responsiveHeight={padding_bottom}
                tilePadding={tilePadding}
                categoryTiers={categoryTiers}
            />
        );
    }
    // component is responsive
    return (
        <>
            <DesktopHPTiers
                header={header}
                headerColor={title_font_color}
                headerLineBorder={header_line_border}
                data={category_group}
                maxPerRow={max_per_row}
                responsiveHeight={padding_bottom}
                tilePadding={tilePadding}
                categoryTiers={categoryTiers}
            />
        </>
    );
};

GraphqlHPTiers.propTypes = {
    data: shape({
        max_per_row: number,
    }),
    categoryTiers: bool,
};

GraphqlHPTiers.defaultProps = {
    data: {
        max_per_row: 2,
    },
    categoryTiers: false,
};

export default (GraphqlHPTiers);
